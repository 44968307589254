/** @jsx jsx */
import { jsx, css, withTheme } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";

import Seo from "../components/Seo";
import RuggedBorder from "../components/RuggedBorder";
import Wrapper from "../components/Wrapper";
import Button from "../components/Button";
import { H1, H2, H3, H4 } from "../components/text";

import { links } from "../config";

import BackgroundMobileImg from "../images/background-mobile.jpg";
import BackgroundImg from "../images/background.jpg";

import alexaImg from "../images/grafik-alexa.png";
import alexaImg2x from "../images/grafik-alexa@2x.png";
import { Link } from "gatsby";

// markup
const IndexPage = withTheme(({ theme }) => {
  return (
    <main
      css={css`
        text-align: center;
        background: url(${BackgroundMobileImg}) top center no-repeat;
        /* background-size: 100% auto; */
        padding-top: 36px;
        overflow: hidden;

        ${theme.funcs.mediaquery(
          "xl",
          `background-image: url(${BackgroundImg});
            // background-size: initial;
            padding-top: 76px;
          `
        )}
      `}
    >
      <Seo />
      <Wrapper>
        <H4
          as="p"
          css={css`
            text-transform: uppercase;
            letter-spacing: 0.065em;
            ${theme.funcs.mediaquery(
              "xl",
              `margin-bottom: 10px;
          `
            )}
          `}
        >
          Willkommen bei
        </H4>
        <H1>TKKG – Mein Abenteuer</H1>
        <H2
          as="p"
          css={css`
            margin: 20px 0 10px;
            ${theme.funcs.mediaquery(
              "xl",
              `margin: 75px 0 25px;
          `
            )}
          `}
        >
          Kannst du als TKKGs Online-Agent*in dabei helfen, den Fall "Die
          Goldene Maske" zu lösen?
        </H2>
        <div
          css={css`
            ${theme.funcs.mediaquery(
              "xl",
              `
          display: flex;
          > * {
            width: 50%;
            margin: 10px;
          }
          `
            )}
          `}
        >
          <div
            css={css`
              ${theme.funcs.mediaquery(
                "xl",
                `
              display: flex;
              flex-direction: column;
              justify-content: space-between;
          `
              )}
            `}
          >
            <p>
              Du hast den Alexa Skill „TKKG – Mein Abenteuer“ bereits aktiviert
              und weißt, was zu tun ist?
            </p>
            <StaticImage
              src="../images/grafik-ordner.png"
              alt="TKKG Ordner"
              layout="constrained"
              placeholder="none"
              css={css`
                display: none;
                ${theme.funcs.mediaquery(
                  "xl",
                  `
              display: block;
          `
                )}
              `}
            />
            <div>
              <Button
                as={Link}
                to={links.app}
                color="white"
                bgcolor="#A50A19"
                css={css`
                  margin: 20px 0;
                `}
              >
                Zu den Ordnern
              </Button>
            </div>
          </div>
          <div
            css={css`
              ${theme.funcs.mediaquery(
                "xl",
                `
              display: flex;
              flex-direction: column;
              justify-content: space-between;
          `
              )}
            `}
          >
            <p>
              Solltest du den Alexa Skill noch nicht aktiviert haben, dann
              klicke hier:
            </p>
            <div
              aria-label="„Alexa, starte TKKG – Mein Abenteuer.“"
              css={css`
                display: none;
                ${theme.funcs.mediaquery(
                  "xl",
                  `
              display: block;
          `
                )}
                background: url(${alexaImg}) center no-repeat,
                  radial-gradient(
                    circle,
                    rgba(215, 224, 237, 0.4) 0%,
                    rgba(215, 224, 237, 0) 42%
                  );
                background-size: contain, contain;
                width: 360px;
                max-width: 97%;
                margin: 0 auto;

                &:after {
                  content: "";
                  display: block;
                  height: 0;
                  padding-bottom: 44.444%;
                }

                @media screen and (-webkit-min-device-pixel-ratio: 2),
                  screen and (min-resolution: 192dpi),
                  screen and (min-resolution: 2dppx) {
                  background-image: url(${alexaImg2x}),
                    radial-gradient(
                      circle,
                      rgba(215, 224, 237, 0.4) 0%,
                      rgba(215, 224, 237, 0) 42%
                    );
                }
              `}
            ></div>
            <div>
              <Button
                href={links.alexaSkill}
                target="_blank"
                rel="noopener noreferrer"
                css={css`
                  margin: 20px 0;
                `}
              >
                Zum Alexa Skill
              </Button>
            </div>
          </div>
        </div>
        <div>
          <p
            css={css`
              font-size: 18px;
              margin-bottom: 1em;
            `}
          >
            Die Aktivierung des Skills ist Voraussetzung um den Fall mit TKKG
            lösen zu können. Weitere Infos findest du{" "}
            <Link to={links.faq}>HIER</Link>. 
          </p>
        </div>
      </Wrapper>
      <RuggedBorder>
        <StaticImage
          src="../images/tkkg-mein-abenteuer.jpg"
          layout="fullWidth"
          quality="100"
          placeholder=""
          alt=""
          css={css`
            margin: 0 -12.5%;

            ${theme.funcs.mediaquery(
              "lg",
              `
            margin: 0;
            `
            )}
          `}
        />
      </RuggedBorder>
      <Wrapper>
        <H3
          css={css`
            margin-top: 26px;
            ${theme.funcs.mediaquery(
              "xl",
              `margin-top: 85px;
          `
            )}
          `}
        >
          Hilf TKKG dabei, die goldene Aztekenmaske zu finden und löse knifflige
          Rätsel und spannende Aufgaben.
        </H3>
        <StaticImage
          src="../images/goldene-maske.jpg"
          layout="constrained"
          width={662}
          quality="80"
          alt=""
          css={css`
            margin: 40px 0 30px;
            ${theme.funcs.mediaquery(
              "xl",
              `
            margin: 60px 0 50px;
          `
            )}
          `}
        />
        <p>
          Eigentlich wollen Tim, Karl, Klößchen und Gaby nachts auf dem alten
          verlassenen Friedhof nur Fledermäuse für ihr Bio-Projekt beobachten,
          da entdecken sie eine vermooste Felswand mit geheimnisvollen Zeichen
          drauf. Gerade als TKKG die Wand untersuchen wollen, fallen plötzlich
          Schüsse, ein Motorrad heult auf und verschwindet im Nebel. Wer war
          das? Und was hat es mit der Felswand auf sich?
        </p>
      </Wrapper>
      <div
        css={css`
          position: relative;
          padding: 105px 0 20px;
          ${theme.funcs.mediaquery(
            "xl",
            `
          padding: 105px 0 25px;
          `
          )}
        `}
      >
        <StaticImage
          src="../images/grafik-kloesschen.jpg"
          alt="Klößchen"
          layout="constrained"
          width={200}
          quality="70"
          placeholder=""
          css={css`
            border-radius: 100px;
            max-width: 44%;
            -webkit-mask-image: -webkit-radial-gradient(white, black);
          `}
        />
        <StaticImage
          src="../images/grafik-verbrecherfoto.png"
          alt="Klößchen"
          layout="constrained"
          width={192}
          quality="70"
          placeholder=""
          css={css`
            position: absolute;
            left: 0;
            top: 50%;
            max-width: 20.53%;
            transform: translateY(-50%);
            ${theme.funcs.mediaquery(
              "md",
              `
          max-width: 100px;
          `
            )}
            ${theme.funcs.mediaquery(
              "xxl",
              `
          max-width: 10%;
          `
            )}
            ${theme.funcs.mediaquery(
              "x3l",
              `
          max-width: none;
          `
            )}
          `}
        />
        <StaticImage
          src="../images/grafik-fingerabdruck.png"
          alt="Klößchen"
          layout="constrained"
          width={189}
          quality="70"
          placeholder=""
          css={css`
            position: absolute;
            right: 0;
            top: 50%;
            max-width: 20.53%;
            transform: translateY(-50%);
            ${theme.funcs.mediaquery(
              "md",
              `
          max-width: 100px;
          `
            )}
            ${theme.funcs.mediaquery(
              "xxl",
              `
          max-width: 10%;
          `
            )}
            ${theme.funcs.mediaquery(
              "x3l",
              `
          max-width: none;
          `
            )}
          `}
        />
      </div>
      <Wrapper
        css={css`
          padding-bottom: 45px;
        `}
      >
        <H3
          css={css`
            margin-bottom: 25px;
          `}
        >
          TKKG schalten einen Teilnehmenden ihres neuen
          Meisterdetektiv–Programms dazu{" "}
          <span
            css={css`
              display: inline-block;
              word-wrap: none;
            `}
          >
            – und zwar DICH!
          </span>
        </H3>
        <p>
          Gemeinsam folgt ihr einer Spur von Hinweisen, und schnell wird klar,
          dass irgendwo in der Millionenstadt ein sagenumwobener Schatz
          verborgen liegen muss: die Goldmaske des letzten Aztekenkönigs! Ein
          berühmter Archäologe hat sie vor seinem Tod in einer rätselhaften
          Katakombe versteckt. Jedoch seid ihr nicht die Einzigen, die die Maske
          finden wollen.
        </p>
        <p
          css={css`
            margin-top: 1.5em;
          `}
        >
          Jetzt geht das Abenteuer erst richtig los! Vor dir und TKKG liegt ein
          spannender Fall mit kniffligen Aufgaben, Rätseln und schweren
          Entscheidungen.
        </p>
        <p
          css={css`
            margin-top: 1.5em;
          `}
        >
          Gibt es die goldene Maske der Azteken wirklich? Und was bedeutet die
          geheimnisvolle Karte im alten Tagebuch des Archäologen?
        </p>
        <p
          css={css`
            margin-top: 1.5em;
          `}
        >
          Ob TKKG am Ende die Goldene Maske in ihren Händen halten werden, liegt
          allein an dir!
        </p>
      </Wrapper>
      <RuggedBorder
        css={css`
          background-color: ${theme.color["spot1-light"]};
          padding: 70px 0;
          ${theme.funcs.mediaquery(
            "xl",
            `padding: 130px 0;
          `
          )}
        `}
      >
        <Wrapper>
          <p
            css={css`
              margin-bottom: 1.5em;
            `}
          >
            Um „TKKG - Mein Abenteuer“ spielen zu können, benötigst du ein
            Amazon Alexa Gerät oder die Alexa App auf deinem Smartphone. Der
            Skill ermöglicht dir den Zugang zum interaktiven Hörspielfall, den
            Rätseln und allen Passwörtern, die du zum Öffnen der Ordner mit den
            Beweisstücken brauchst. In den Ordnern befinden sich wichtige
            Hinweise und Indizien, die dir bei deinen Ermittlungen helfen.
          </p>
          <p
            css={css`
              margin-bottom: 1.5em;
            `}
          >
            Du hast Fragen oder benötigst Hilfe bei der Aktivierung des Skills?
            Im Ordner FAQ findest du hilfreiche Infos.{" "}
          </p>
          <p
            css={css`
              margin-bottom: 45px;
            `}
          >
            Oder schreibe einfach eine E-Mail an{" "}
            <a href="mailto:info@europa-kinderwelt.de">
              info@europa-kinderwelt.de
            </a>
            .
          </p>

          <StaticImage
            src="../images/grafik-alexa-skill.png"
            alt=""
            layout="constrained"
            width={569}
            formats={["png"]}
            placeholder=""
          />
        </Wrapper>
      </RuggedBorder>
      <RuggedBorder
        css={css`
          background-color: ${theme.color["light-blue"]};
          margin-top: -4vw;
          margin-bottom: -4vw;
          z-index: 1;
        `}
        borderColor={theme.color["spot1-light"]}
      >
        <Wrapper
          css={css`
            padding-top: 120px;
            padding-bottom: 120px;
            color: ${theme.color.spot1};
          `}
        >
          <H3>
            Wie gut schlägst du dich als TKKGs Online-Agent*in bei den
            Ermittlungen? Bist du eine Spürnase, ein Juniordetektiv, ein
            Profi-Ermittler oder sogar ein Meisterdetektiv?
          </H3>
          <div
            css={css`
              ${theme.funcs.mediaquery(
                "xl",
                `
              display: flex;
              align-items: center;
              text-align: left;
              margin-top: 2em;
              >* {
                width: 50%;
              }
              `
              )}
            `}
          >
            <div>
              <StaticImage
                src="../images/grafik-urkunde.png"
                alt="Meisterdetektiv-Urkunde"
                layout={"constrained"}
                placeholder="none"
                width={411}
                css={css`
                  width: 90%;
                  max-width: 411px;
                `}
              />
            </div>
            <div>
              <p>
                Je mehr Rätsel du auf Anhieb richtig löst, desto höher fällt am
                Ende des Falls dein Detektivrang aus. Drucke dir deine
                persönliche Urkunde mit deinem erspielten Rang aus. Hast du das
                Zeug zum Meisterdetektiv?
              </p>
              <Button
                as={Link}
                to={links.app}
                color="white"
                bgcolor="#A50A19"
                css={css`
                  margin: 20px 0;
                `}
              >
                Zu den Ordnern
              </Button>
            </div>
          </div>
        </Wrapper>
      </RuggedBorder>
    </main>
  );
});

export default IndexPage;
